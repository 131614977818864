/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _pagination.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
   Pagination
   ================================================== */

.pagination,
.wp-pagenavi{
  width: 100%;
  margin: 60px 0;
  padding: 0;
  text-align: center;

  a,
  & > span {
    position: relative;
    display: inline-block;
    margin: 0.5em 0.8em;
    padding: 0.8em 1.2em;
    text-decoration: none;
    width: auto;
    color: $colorBase;
    background: $colorBorder;

    @include max-width(sm){
      @include font-size(1.2);
    }
  }
  a:hover {
    opacity: 0.5;
  }
  .current {
    color: #fff;
    background: $colorMain4;
  }
}
.post-content{
  .pagination,
  .wp-pagenavi{
    margin: 0;
  }
}