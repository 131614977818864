@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _common.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
   Common Content
================================================== */

.inner {
  @include inner;
}

.section {
  padding: 80px 0;

  @include max-width(md){
    padding: 40px 0;
  }

  &.is--start{
    padding-top: 0;
  }
  &.is--end{
    padding-bottom: 0;
  }
}

.c-block{
  display: inline-block;
}

.c-trim{
  @include c-trim;

  &:after{
    @include c-trim__after;
  }
  .c-trim__inner{
    @include c-trim__inner;

    img{
      @include c-trim__img;
    }
  }
}