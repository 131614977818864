@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _display.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
   Display Style
================================================== */

.c-min-lg{
  @include max-width(lg){
    display: none !important;
  }
}
.c-max-lg{
  @include min-width(lg){
    display: none !important;
  }
}
.c-min-md{
  @include max-width(md){
    display: none !important;
  }
}
.c-max-md{
  @include min-width(md){
    display: none !important;
  }
}
.c-min-sm{
  @include max-width(sm){
    display: none !important;
  }
}
.c-max-sm{
  @include min-width(sm){
    display: none !important;
  }
}
.c-min-xs{
  @include max-width(xs){
    display: none !important;
  }
}
.c-max-xs{
  @include min-width(xs){
    display: none !important;
  }
}
.c-block{
  display: inline-block;
}


