@charset "utf-8";

/* ==================================================
   Layout
================================================== */

/* Block
-------------------------------------------------- */

/**
 * Element
 */

/* ==================================================
   Fonts
================================================== */

/* ==================================================
   Base
================================================== */

/* Mixin
-------------------------------------------------- */
@import "config"; // 基本設定
@import "mixin"; // @mixin

/* Base
-------------------------------------------------- */
@import "./foundation/color"; // カラー指定(よく使うカラーやベースカラーはここで指定)
//@import "./foundation/normalize"; // normalize.css
//@import "./foundation/base"; // base.css

/* Layout
-------------------------------------------------- */

//@import "./layout/header"; // ヘッダーレイアウト
//@import "./layout/footer"; //フッターレイアウト
@import "./layout/common"; // コンテンツ全体のレイアウト

/* Object
-------------------------------------------------- */

/**
 * Component
 */
//@import "./object/component/heading"; // 見出しレイアウト
//@import "./object/component/button"; // ボタン
//@import "./object/component/entry-content"; // 記事
//@import "./object/component/hero"; // メインビジュアル
@import "./object/component/pagination"; // ページネーション
//@import "./object/component/breadcrumb"; // パンくずリスト

/**
 * Utility
 */
@import "./object/utility/flexbox"; // flexbox
@import "./object/utility/display"; // 要素の表示・非表示

/* ==================================================
   Component
================================================== */

//表示切り替え
.p-display-switching{
  text-align: right;
  margin-bottom: 15px;

  dl{
    @include flex(flex-end, center, row, nowrap);

    dt{
      display: inline-block;
      margin-right: 10px;
    }
    dd{
      display: inline-block;

      &:nth-child(n+2){
        margin-right: 10px;
      }
    }
  }
}

/* ==================================================
   Search
================================================== */

.p-search{
  padding-bottom: 60px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  &-container{
    padding: 0 20px 20px !important;
    border-radius: 0 0 4px 4px;
    background-color: #004643;
    border-top: none;

    @include max-width(sm){
      padding: 1em !important;
    }

    & > div{
      background-color: #fff;
      padding: 1.2em 1em 1em;
    }
  }

  &-lead{
    padding: 60px 0;
    @include font-size(1.6);
    line-height: 1.8;

    @include max-width(sm){
      @include font-size(1.3);
      padding: 2em 0;
    }

    a{
      display: inline-block;
      text-decoration: underline;
      margin: 0 0.2em;
    }
  }


  &-title{
    position: relative;
    top: 4px;
    @include flex(center, center, row, nowrap);
    background-color: #004643;
    padding: 0.8em 20px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;

    @include max-width(sm){
      padding: 0.8em;
    }

    img{
      margin-right: 1em;
      margin-left: -1em;
    }

    &:after{
      content: '';
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%) rotate(180deg);
      background-image: url(../img/post/icon_angle.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 12px;
      height: 6px;
    }

    &.js-active{
      border-radius: 4px 4px 0 0;


      &:after{
        transform: translateY(-50%) rotate(0);
      }
    }
  }

  //該当なしの表記
  &-none{
    text-align: center;
    @include font-size(1.8);
    font-weight: 600;
    color: #777;

    @include max-width(sm){
      @include font-size(1.4);
    }
  }

  .searchandfilter{

    ul{
      @include flex(flex-start, stretch, row, wrap);
    }

    input{
      font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
      @include font-size(1.6);
    }

    //キーワード検索
    .sf-field-search{
      flex: 1 1 calc( 100% - 44px );
      height: 44px;
      padding: 0;

      @include max-width(sm){
        flex-basis: calc( 100% - 40px );
        height: 40px;
      }

      label{
        display: block;
        width: 100%;

        input{
          display: block;
          width: 100%;
          border: 1px solid #004643;
          border-radius: 0;
          height: 44px;
          line-height: 44px;
          padding: 0.5em;

          @include max-width(sm){
            height: 40px;
            line-height: 40px;
            @include font-size(1.4);
          }
        }
      }
    }

    //見出し
    h4{
      @include flex(flex-start, center, row, nowrap);
      border-radius: 5px;
      padding: 0.3em 0.5em;
      background-color: #DCEFEA;
      font-weight: 600;

      @include max-width(sm){
        @include font-size(1.4);
      }

      &:before{
        width: 1.6em;
        height: 1.6em;
        margin-right: 1.6em;
      }
    }

    //チェックボックス
    [class*="sf-field-taxonomy"]{
      flex: 0 0 100%;

      ul{
        padding: 0.5em 0 0;
        line-height: 1.6;

        li{
          @include flex(flex-start, center, row, nowrap);
          width: auto;
          padding-right: 10px;

          @include max-width(sm){
            @include font-size(1.3);
          }

          input{
            position: relative;
            top: -0.1em;
            font-size: 1em;
            vertical-align: middle;
            width: 1em;
            height: 1em;
            margin: 0;
          }
        }
      }
    }

    //検索ボタン
    .sf-field-search + .sf-field-submit{
      width: 44px;
      height: 44px;
      padding: 0;

      @include max-width(sm){
        right: 0;
        width: 40px;
        height: 40px;
      }

      input{
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        background-color: #004643;
        background-image: url(../img/post/icon_search.svg);
        background-repeat: no-repeat;
        background-position: left 40% top 40%;
        background-size: auto 60%;
        box-shadow: none;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }

    //絞り込みボタン
    [class*="sf-field-taxonomy"] + .sf-field-submit{
      @include flex(center, center, column, nowrap);
      max-width: 222px;
      border: none;
      position: relative;
      text-align: center;
      background-color: #F9BC60;
      @include font-size(1.5);
      font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
      max-height: 40px;
      margin: 40px auto 0;
      padding: 0;

      @include max-width(xs){
        margin-top: 1em;
        max-width: 100%;
      }

      /*-
      &:after{
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3.5px 0 3.5px 6px;
        border-color: transparent transparent transparent #C48424;
      }
      -*/

      input{
        background-color: transparent;
        border: none;
        font-weight: 600;
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
      }
    }
  }

  //事業支援施策
  &.is--government{
    position: relative;
    //padding-top: 60px;

    /*-
    .sf-field-search{
      position: absolute;
      top: 0;
      width: calc( 100% - 44px );
      max-width: calc( 800px - 44px );
      left: calc( 50% - 400px );

      @include max-width(lg){
        left: 0;
        width: calc( 100% - 44px );
        max-width: calc( 100% - 44px );
      }
      @include max-width(sm){
        left: 0;
        width: calc( 100% - 40px );
        max-width: calc( 100% - 40px );
      }
    }
    .sf-field-search + .sf-field-submit{
      position: absolute;
      top: 0;
      right: calc( 50% - 400px + 1px );

      @include max-width(lg){
        right: 0;
      }
    }
    -*/


    .sf-field-taxonomy-subject{
      padding-top: 15px;

      h4{
        &:before{
          content: url(../img/post/icon_man.svg);
        }
      }
    }
    .sf-field-taxonomy-details{
      h4{
        &:before{
          content: url(../img/post/icon_sheet.svg);
        }
      }
    }
  }
  //サポーター団体
  &.is--supporters{

    .searchandfilter{

      ul{
        padding: 0;
      }

      .sf-field-taxonomy-industry{
        padding: 0;
      }
    }
  }

  //バックナンバー
  &.is--backnumber{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* ==================================================
   Remodal
================================================== */

.remodal{
  background-color: transparent !important;
  max-width: 1000px !important;
  padding: 0 !important;
}

.p-modal{
  text-align: left;
  border-radius: 5px;
  padding: 30px 60px;
  border: 1px solid #D2D2D2;
  box-shadow: 2px 3px 0 #D2D2D2;
  background-color: #fff;

  @include max-width(sm){
    padding: 2em 1em;
  }

  &__top{
    @include flex(flex-start, stretch, row, nowrap);
    margin-bottom: 25px;

    @include max-width(sm){
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .c-media{
      width: 330px;
      margin-right: 30px;
      flex: 0 1 40%;

      @include max-width(sm){
        width: 100%;
        flex: 0 0 100%;
        margin-right: 0;
      }

      img{
        object-fit: contain;
      }
    }
    .c-text{
      flex: 0 1 60%;
      padding-top: 0.5em;

      @include max-width(sm){
        width: 100%;
        flex: 0 0 100%;
        padding-top: 1em;
      }

      .c-title{
        @include font-size(2.0);
        line-height: 1.5;
        margin-bottom: 1em;
      }
    }
  }
  &__contents{

    dt,dd{
      @include max-width(sm){
        @include font-size(1.2);
      }
    }

    dt{
      @include flex(flex-start, center, row, nowrap);
      border-radius: 5px;
      padding: 0.3em 0.5em;
      background-color: #DCEFEA;
      font-weight: 600;

      img{
        width: 1.6em;
        margin-right: 1.6em;
        height: auto;
      }
    }

    //横並びリスト
    &.is--row{
      @include flex(flex-start, flex-start, row, wrap);

      dt,dd{

        @include min-width(sm){
          &:nth-of-type(n+2){
            margin-top: 0.8em;
          }
        }
      }

      dt{
        flex: 0 0 8.4em;
        margin-right: 1.2em;
      }
      dd{
        flex: 1 1 calc( 100% - 9.6em );
        align-self: center;
        line-height: 1.6;

        @include max-width(sm){
          padding: 0.5em 0 1em;
          width: 100%;
          flex: 0 0 100%;
        }
      }

      //折返しなし
      &.is--nowrap{
        @include min-width(sm){
          flex-wrap: nowrap;
        }

        dt,dd{
          margin-top: 0;
        }
      }
    }

    //全幅テキストエリア
    &.is--column{

      dd{
        padding: 1em 2em 2em;
        line-height: 1.6;

        @include max-width(sm){
          padding: 0.5em 0 1em;
        }
      }
    }

    & + &{
      @include min-width(sm){
        margin-top: 1em;
      }
    }
  }

  //テーブル
  &__table{
    table-layout: fixed;
    width: 100%;

    @include max-width(sm){
      tbody,
      tr,
      th,
      td{
        display: block;
      }
    }

    th,td{
      padding: 1em;
      line-height: 1.6;
      border-bottom: 1px solid #89BAAC;
      @include font-size(1.4);
      vertical-align: middle;
    }

    th{
      background-color: #ABD1C6;
      text-align: center;

      @include min-width(sm){
        width: 160px;
      }
      @include max-width(sm){
        width: 100%;
        padding: 0.3em 1em;
      }
    }
    td{
      background-color: #F9FFFD;

      @include max-width(sm){
        padding: 0.8em 1em 1.5em;
      }

      a{
        text-decoration: underline;
        color: #004F80;
      }
    }
  }

  .c-btn{
    @include flex(center, center, column, nowrap);
    max-width: 222px;
    border: none;
    position: relative;
    padding: 0.8em;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    @include font-size(1.5);
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    max-height: 40px;

    @include max-width(sm){
      @include font-size(1.3);
    }

    &:after{
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px 0 3.5px 6px;
    }

    &.is--hp{
      background-color: #F9BC60;
      margin-right: 1.6em;

      @include max-width(sm){
        margin-right: 2%;
      }

      &:after{
        border-color: transparent transparent transparent #C48424;
      }
    }
    &.is--close,
    &.is--more{
      background-color: #ABD1C6;

      &:after{
        border-color: transparent transparent transparent #89BAAC;
      }
    }
  }

  &__btn{
    @include flex(center, stretch, row, nowrap);

    .c-btn{
      flex: 0 1 calc( 50% - 0.8em );

      @include max-width(sm){
        flex: 1 1 49%;
        max-width: 100%;
      }
    }
  }
  //イベント
  &.is--events{
    @include max-width(sm){
      padding: 1em;
    }
  }
  //アドバイザー紹介
  &.is--advisors{

    .c-media{
      flex: 0 0 220px;

      &:after{
        padding-bottom: 75%;
      }
    }

    .c-text{
      @include flex(flex-start, flex-start, column, nowrap);

      .p-advisors__en{
        margin-bottom: 0.5em;
      }

      .c-title{
        @include font-size(2.2);
      }
      .p-modal__contents.is--row{
        margin-top: auto;

        dt{
          flex: 0 0 12em;
        }
        dd{
          flex: 1 1 calc( 100% - 13.2em );
        }
      }
    }
  }
  //サポーター団体
  &.is--supporters{

    @include max-width(sm){
      padding: 1em;
    }

    .c-media{
      @include min-width(sm){
        flex: 0 0 220px;
      }

      &:after{
        padding-bottom: 75%;
      }
    }

    .c-text{

      .c-title{
        @include font-size(1.7);
      }

      .p-supporters__term{
        li{
          display: inline-block;
          margin-right: 0.5em;
          margin-bottom: 0.5em;
        }
      }

      .p-modal__contents.is--row{
        margin-top: auto;

        dt{
          flex: 0 0 12em;
        }
        dd{
          flex: 1 1 calc( 100% - 13.2em );
        }
      }
    }
  }
  //公募・補助金・助成金情報
  &.is--subsidy,
  &.is--government,
  &.is--overseas,
  &.is--backnumber{

    .c-text{
      .c-title{
        @include font-size(2.0);
        @include flex(space-between, center, row, nowrap);
        margin-bottom: 1em;

        @include max-width(sm){
          @include font-size(1.6);
          padding-bottom: 0.8em;
          border-bottom: 1px solid #d2d2d2;
        }

        .c-btn{
          flex: 0 0 222px;
          margin: 0 0 0 20px;
        }
      }
    }

    .p-modal__contents.is--row{
      dt{
        flex: 0 0 10em;
      }
      dd{
        width: 100%;
        flex: 1 1 calc( 100% - 11.2em );

        @include max-width(sm){
          flex-basis: 100%;
        }
      }
    }

    .p-modal__btn{
      margin-top: 40px;

      @include max-width(sm){
        margin-top: 1em;
      }
    }
  }
  //事業支援施策
  &.is--government{
    .p-modal__contents.is--row{

    }
  }
}

//メールモーダル

[data-remodal-target="modal-email"],
[data-remodal-target="modal-registration"]{
  cursor: pointer;
}

.p-email{
  padding: 40px 120px;

  @include max-width(md){
    padding: 40px 5%;
  }

  .maintitle{
    font-weight: 400;
    @include font-size(3.0);
    line-height: 1.11111;
    font-weight: 700;
    color: #000000;
    white-space: pre-wrap;
    margin: 0 0 1em;
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    padding-top: 1em;
    padding-bottom: 0.5em;
    border-bottom: 1px dashed #ED9771;

    @include max-width(sm){
      @include font-size(2.0);
    }

    &:before{
      content: none;
    }
    &:after{
      top: 0;
      width: 2em;
      height: 2em;
    }
  }

  &-list{
    &__item{
      list-style-type: disc;
      list-style-position: inside;
      @include font-size(1.6);

      @include max-width(sm){
        @include font-size(1.4);
      }

      @include gap-mg(top, 1.5em);
    }
  }
  &-note{
    @include font-size(1.5);
    margin-top: 2em;

    @include max-width(sm){
      @include font-size(1.3);
    }
  }
  &-template{
    @include flex(space-between, stretch, row, wrap);
    margin-top: 30px;

    &__item{
      border: 1px solid #89BAAC;
      flex: 0 0 calc( 50% - 10px );

      @include max-width(sm){
        flex: 0 0 100%;

        &:nth-child(n+2){
          margin-top: 20px;
        }
      }

      h3{
        background-color: #89BAAC;
        color: #fff;
        font-weight: 600;
        padding: 0.5em 1em;
        @include font-size(2.0);

        @include max-width(sm){
          @include font-size(1.6);
        }
      }
      dl{
        padding: 2em;
        @include font-size(1.6);

        @include max-width(sm){
          @include font-size(1.4);
        }

        dt{
          margin-bottom: 1.5em;
        }
        dd{
          ul{
            li{
              @include gap-mg(top, 0.5em);

              &.has--indent{
                padding-left: 1em;
              }
            }
          }
        }
      }

      & > ul{
        padding: 2em 2em 2em 3em;
        @include font-size(1.6);
        text-indent: -1em;

        @include max-width(sm){
          @include font-size(1.4);
        }

        li{
          list-style-position: inside;
          list-style-type: disc;
          @include gap-mg(top, 1em);
        }
      }

      &.is--registration,
      &.is--en{
        flex: 0 0 100%;
      }

      &.is--consultation{
        flex: 0 0 100%;
        margin-top: 20px;

        h3{
          background-color: #ED9771;
          border-color: #ED9771;
          background-image: url(../img/service/constraction_request_contents_decoration.svg);
          background-repeat: no-repeat;
          background-position: left 1em center;
          background-size: auto 1em;
          padding-left: 3em;
        }
      }
    }
  }

  .p-modal__btn{
    padding-top: 40px;
  }
}

/* ==================================================
   Events
================================================== */

.p-events{

  &-list{

    &__item{

      .c-media{
        margin-bottom: 0.5em;

        img{
          object-fit: contain;
        }
      }

      &__top{
        padding: 1em;
      }
    }

    //タイル表示
    &.is--tile{
      @include flex(flex-start, flex-start, row, wrap);


      .p-events-list__item{
        background-color: #fff;
        box-shadow: 2px 3px 0 #D2D2D2;
        border: 1px solid #D2D2D2;
        width: 25%;
        flex: 0 1 calc( 25% - 15px );
        cursor: pointer;
        border-radius: 4px;

        @include max-width(sm){
          width: 100%;
          flex: 0 1 100%;
        }

        @include min-width(sm){
          &:not(:nth-child(4n)){
            margin-right: 20px;
          }
          &:nth-child(n+5){
            margin-top: 25px;
          }
        }

        @include max-width(sm){
          &:nth-child(n+2){
            margin-top: 20px;
          }
        }

        @include max-width(sm){
          &__top{
            @include flex(flex-start, flex-start, row, wrap);
          }

          .c-media{
            flex: 0 0 120px;
          }
        }

        a{
          @include flex(space-between, flex-start, column, nowrap);
          height: 100%;
        }

        .c-text{
          @include max-width(sm){
            flex: 1 1 calc( 100% - 120px );
            padding-left: 1em;
          }

          .c-title{
            @include font-size(1.4);
            line-height: 1.5;
          }
        }
      }
    }

    //リスト表示
    &.is--list{

      .p-events-list__item{
        @include gap-mg(top, 35px);
      }
    }

  }

  &__schedule{
    margin-top: 1.5em;
    @include font-size(1.2);
    @include flex(flex-start, stretch, row, wrap);

    dt,dd{
      &:nth-of-type(n+2){
        margin-top: 0.5em;
      }
    }

    dt{
      flex: 0 0 3em;
    }
    dd{
      flex: 1 1 calc( 100% - 3em );
    }
  }
  &__more{
    position: relative;
    width: 100%;
    padding: 0.8em;
    border-top: 1px dashed #89BAAC;
    text-align: center;
    font-weight: 600;

    @include max-width(sm){
      @include font-size(1.2);
    }

    &:after{
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px 0 3.5px 6px;
      border-color: transparent transparent transparent #000000;
    }
  }
}

/* ==================================================
   Advisors
================================================== */

.p-advisors{

  &-list{

    @include flex(flex-start, flex-start, row, wrap);


    &__item{

      background-color: #fff;
      box-shadow: 2px 3px 0 #D2D2D2;
      width: 33.3333%;
      flex: 0 1 calc( 33.3333% - 40px / 3 );
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #D2D2D2;

      @include max-width(sm){
        width: 100%;
        flex: 0 0 100%;
      }

      @include min-width(sm){
        &:not(:nth-child(3n)){
          margin-right: 20px;
        }
        &:nth-child(n+4){
          margin-top: 20px;
        }
      }
      @include max-width(sm){
        &:nth-child(n+2){
          margin-top: 20px;
        }
      }

      .c-media{
        margin-bottom: 0.5em;

        &:after{
          padding-bottom: 73.10%;
        }

        img{
          object-fit: contain;
        }
      }

      .c-text{
        .c-title{
          @include font-size(1.8);
          line-height: 1.5;

          @include max-width(sm){
            @include font-size(1.5);
          }
        }
      }

      &__top{
        padding: 1em;
      }

    }
  }

  //属性
  &__attribute{
    display: block;
    min-width: 118px;
    border-radius: 5px;
    padding: 0.5em;
    margin-bottom: 0.5em;
    background-color: #DCEFEA;
    text-align: center;
    @include font-size(1.4);
    line-height: 1.3;
  }

  //肩書
  &__position{
    margin-top: 1em;

    li{
      list-style-type: disc;
      list-style-position: inside;

      @include gap-mg(top, 0.5em);


      @include max-width(sm){
        @include font-size(1.2);
      }
    }

    & + .p-modal__contents{
      margin-top: 1em !important;
    }
  }

  &__more{
    position: relative;
    padding: 0.8em;
    border-top: 1px dashed #89BAAC;
    text-align: center;
    font-weight: 600;

    @include max-width(sm){
      @include font-size(1.2);
    }

    &:after{
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px 0 3.5px 6px;
      border-color: transparent transparent transparent #000000;
    }
  }
}

/* ==================================================
   Supporters
================================================== */

.p-supporters{

  .inner{
    @include inner(1592);
  }

  .js-toggle{

    &-contents{
      display: none;
      padding-top: 35px;
    }
  }

  &-list{

    &__item{

      .c-media{
        margin-bottom: 0.8em;

        img{
          object-fit: contain;
        }
      }

      &__top{
        padding: 1em;

        @include max-width(sm){
          padding: 0.6em;
        }
      }
    }

    //タイル表示
    &.is--tile{
      @include flex(flex-start, stretch, row, wrap);


      .p-supporters-list__item{
        background-color: #fff;
        box-shadow: 2px 3px 0 #D2D2D2;
        width: 14.2857%;
        flex: 0 1 calc( 14.2857% - 18px );
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid #D2D2D2;

        @include and(1279, 768){
          width: 25%;
          flex: 0 1 calc( 25% - 15px );
        }
        @include max-width(sm){
          width: 50%;
          flex: 0 1 calc( 50% - 10px );
        }

        @include min-width(lg){
          &:not(:nth-child(7n)){
            margin-right: 20px;
          }
          &:nth-child(n+8){
            margin-top: 20px;
          }
        }
        @include and(1279, 768){
          &:not(:nth-child(4n)){
            margin-right: 20px;
          }
          &:nth-child(n+5){
            margin-top: 20px;
          }
        }
        @include max-width(sm){
          &:not(:nth-child(2n)){
            margin-right: 10px;
          }
          &:nth-child(n+3){
            margin-top: 20px;
          }
        }

        .c-text{
          .c-title{
            @include font-size(1.4);
            line-height: 1.5;
            word-break: break-all;
          }
          .p-supporters__term{

            li{
              margin-right: 0.5em;
              margin-bottom: 0.5em;
              display: inline-block;
              padding: 0.5em 1em;
              background-color: #fff;
              @include font-size(1.1);
              background-color: #F6CB89;
              text-align: center;
              font-weight: 600;
              line-height: 1.3;

              @include max-width(sm){
                @include font-size(1.0);
              }
            }
          }
        }
      }
    }

    //リスト表示
    &.is--list{
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;

      .p-supporters-list__item{
        @include gap-mg(top, 35px);
      }
    }

  }

}

/* ==================================================
   Subsidy
================================================== */

.p-subsidy{

  &-list{

    &__item{

      @include gap-mg(top, 35px);
    }
  }
}

/* ==================================================
   Government
================================================== */

.p-government{

  .js-toggle{

    &-contents{
      display: none;
      padding-top: 35px;
    }
  }

  &-nav{
    @include flex(space-between, stretch, row, nowrap);
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 60px;

    .c-btn{
      flex: 0 0 calc( 50% - 10px );
      line-height: 50px;
      height: 50px;
      border-bottom-width: 3px;
      border-bottom-style: solid;
      text-align: center;
      color: #fff;
      @include font-size(1.8);
      border-radius: 4px;
      font-weight: 600;

      &.is--country{
        background-color: #EE8354;
        border-bottom-color: #9F3031;
      }
      &.is--municipality{
        background-color: #89BAAC;
        border-bottom-color: #004643;
      }
    }
  }

  &-list{
    &__item{
      .p-modal__contents.is--row{
        align-items: center;
      }
      .organization,
      .country{
        @include min-width(sm){
          max-width: 4em;
        }
      }

      @include gap-mg(top, 35px);
    }
  }

  &__term{

    li{
      display: inline-block;
      margin-right: 0.5em;
    }
  }
}

/* ==================================================
   Overseas
================================================== */

.p-overseas{

  &-imagemap{
    margin: 0 auto;
  }

  &-list{
    &__item{
      @include gap-mg(top, 35px);
    }
  }

  &__term{
    margin-bottom: 1em;

    li{
      display: inline-block;
      margin-right: 0.5em;
      width: 118px;
      border-radius: 5px;
      padding: 0.5em;
      text-align: center;
      @include font-size(1.4);
      color: #fff;

      @include max-width(sm){
        width: auto;
        min-width: 80px;
        @include font-size(1.2);
        border-radius: 2px;
      }

      &.asia,
      &.south-america{
        background-color: #70BCC3;
      }
      &.africa{
        background-color: #F0B04F;
      }
      &.oceania{
        background-color: #B2823B;
      }
      &.europa{
        background-color: #3D85CB;
      }
      &.north-america{
        background-color: #E84F4F;
      }
    }
  }
}

/* ==================================================
   Backnumber
================================================== */

.p-backnumber{


  &-list{
    &__item{

      .p-modal__contents.is--row{
        align-items: center;

        dt{
          img{
            margin-right: 0.8em;
          }

          &.company{
            flex: 0 0 10em;
          }
        }
        dd{
          flex: 1 1 calc( 100% - 11.2em );

          &.company{
            @include flex(space-between, center, row, nowrap);

            .c-btn{
              margin-right: 0;
              margin-left: 1em;
            }
          }
        }
      }
      .organization,
      .country{
        @include min-width(sm){
          max-width: 6em;
        }
      }

      @include gap-mg(top, 35px);
    }
  }

  &__term{

    li{
      display: inline-block;
      margin-right: 0.5em;
    }
  }
}

/* ==================================================
   Custom
================================================== */

#footer > .contents > .columnbox > .detailbox .navitem > .item{
  text-transform: capitalize;
}
.top > .support_information_event-wrap > .eventSlider .eventcard a{
  height: 100%;
}
.top > .support_information_event-wrap > .eventSlider .eventcard a > .read{
  margin-top: auto;
}

/* プライバシーポリシー */
.privacy > .privacy-wrap > .contents > .privacybox > .detail{

  p{
    &:nth-child(n+2){
      margin-top: 1em;
    }
  }
  h3{
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  ul{
    margin-top: 0.5em;
    li{
      list-style-position: inside;
      margin-bottom: 0 !important;
    }
  }
  ol{
    &.list1{
      counter-reset: list1 0;

      & > li{
        padding-left: 3em;
        position: relative;

        &:before{
          position: absolute;
          left: 0;
          top: 0;
          counter-increment: list1 1;
          content: "（" counter(list1) "）";
        }
      }
    }
    &.list2{
      counter-reset: list2 0;

      & > li{
        padding-left: 3em;
        position: relative;

        &:before{
          position: absolute;
          left: 0;
          top: 0;
          counter-increment: list2 1;
          content: "（" counter(list2, upper-alpha) "）";
        }
      }
    }
  }
  .gabox{
    margin-top: 1em;
    padding: 2em;
    background-color: #EBF2F0;

    p,ul{
      font-size: 0.9em;
    }

    h5{
      margin-top: 1em;
      margin-bottom: 0.5em;
    }

    ul{

      li{
        margin-bottom: 0;
      }
    }
  }
}
.fixedcontents{
  @include min-width(md){
    display: none !important;
  }
}
#header > .headerbottom > .logobox{
  min-width: 120px;
}
@media screen and (min-width: 1281px) {
  #header > .headerbottom {
    padding-right: 200px;
  }
}
#header > .headerbottom > .navigationwrap {
  margin-right: 0;
}
#header > .headerbottom > .navigationwrap > .contact{
  right: 0;
}
#header > .headerbottom > .navigationwrap > .navigationitem > .item{
  flex-basis: auto;
}
#header > .headerbottom > .navigationwrap > .navigationitem > .item > a{
  font-size: 1.4rem;
  padding: 0 1.5em;
  white-space: nowrap;
  margin: 0 !important;
}
#header > .headerbottom > .navigationwrap > .navigationitem > .item > a:after{
  right: 0;
  width: 0.5em;
  height: 0.5em;
}
@media screen and (max-width: 1280px) and (min-width: 1025px){
  #header > .headerbottom {
    padding-right: 90px;
  }
  #header > .headerbottom > .navigationwrap > .navigationitem > .item > a{
    font-size: 1.3rem;
  }
}