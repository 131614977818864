@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _c-flexexbox.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

/* ==================================================
  flexbox Style
================================================== */

.c-flex{
  width: 100%;
  display: -webkit-c-flexex;
  display: c-flexex;

  &.c-wrap{
    &--wrap{
      c-flexex-wrap: wrap;
    }
    &--nowrap{
      c-flexex-wrap: nowrap;
    }
  }
  &.c-justify{
    &--between{
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }
    &--start{
      -webkit-justify-content: c-flexex-start;
      justify-content: c-flexex-start;
    }
    &--center{
      -webkit-justify-content: center;
      justify-content: center;
    }
    &--end{
      -webkit-justify-content: c-flexex-end;
      justify-content: c-flexex-end;
    }
  }
  &.c-align{
    &--start{
      align-items: c-flexex-start;
    }
    &--center{
      align-items: center;
    }
    &--end{
      align-items: c-flexex-end;
    }
  }
  &.c-direction{
    &--row{
      c-flexex-direction: row
    }
    &--row-r{
      c-flexex-direction: row-reverse;
    }
    &--col{
      c-flexex-direction: column;
    }
    &--col-r{
      c-flexex-direction: column-reverse;
    }
  }
  .c-basis{
    &--auto{
      c-flexex-basis: auto;
    }
  }

  //子要素の左右位置
  .c-self{
    &--left{
      margin-left: 0;
      margin-right: auto;
    }
    &--right{
    margin-right: 0;
    margin-left: auto;
    }
  }
}
@each $breakpoint, $property in $mq-min-width{
  .c-flex-min--#{$breakpoint} {
    @include max-width(#{$breakpoint}){
      display: block !important;
    }
  }
  .c-flex-max--#{$breakpoint} {
    @include min-width(#{$breakpoint}){
      display: block !important;
    }
  }
}
