@charset "utf-8";

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _color.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */

$colorBase: #202625;
$colorMain: #59ABF3;
$colorMain2: #1D78C7;
$colorMain3: #EEFEFA;
$colorMain4: #F5F5F5;
$colorMain5: #D1E8EF;
$colorMain6: #FFEE00;
$colorMain7: #1F406B;

$colorBorder: #EBEBEB;
$colorBorder2: #ACACAC;
$colorBorder3: #E5E5E5;

/* ==================================================
   Color Stylez
================================================== */

/**
 * SNS Color
 */

$colorLine: #1dcd00;
$colorFacebook: #3B5998;
$colorTwitter: #55acee;
$colorGoogle: #dd4b39;
$colorInstagram: #3f729b;


/* ==================================================
   Color Palette Style
================================================== */

//link
$color-link: $colorMain;
$color-link-hover: $colorMain;

//common
$color-selection: #dedede;
$color-scrollbar: #efefef;

//header
$color-header-btn: black;

//toggle-menu
$color-toggle-hamburger-bg: transparent;
$color-toggle-hamburger-bar: black;
$color-toggle-border: $colorMain;
$color-toggle-bg--open: $colorMain;
$color-toggle-main-text: $colorBase;
$color-toggle-sub-text: $colorBase;

//breadcrumb
$color-breadcrumb-bg: transparent;

//footer
$color-footer-copy: $colorMain;
$color-footer-heading: $colorMain;

//heading
$color-heading: black;

//button
$color-btn: $colorMain;

