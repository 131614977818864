@charset "UTF-8";
/* ==================================================
   Layout
================================================== */
/* Block
-------------------------------------------------- */
/**
 * Element
 */
/* ==================================================
   Fonts
================================================== */
/* ==================================================
   Base
================================================== */
/* Mixin
-------------------------------------------------- */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> _config.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/**
 * Mediaquery
 */
/**
 * Font Size
 */
/**
 * Font Family
 */
/**
 * Inner
 */
/**
 * Image-trim
 */
/**
 * border-radius
 */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> _mixin.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/**
 * Mediaquery Display
 */
/**
 * Clearfix
 */
/**
 * Prefix
 */
/**
 * Position Center（親要素に対して上下中央寄せ）
 */
/**
 * Flexbox
 */
/**
 * CSS grid
 */
/**
 * Color
 */
/**
 * Font size
 */
/**
 * Font-family
 */
/**
 * Transition
 */
/**
 * Calc
 */
/**
 * Inner
 */
/**
 * Image-trim
 */
/**
 * Gap-margin
 */
/**
 * Gap-padding
 */
/* Base
-------------------------------------------------- */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> base -> _color.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Color Stylez
================================================== */
/**
 * SNS Color
 */
/* ==================================================
   Color Palette Style
================================================== */
/* Layout
-------------------------------------------------- */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> layout -> _common.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Common Content
================================================== */
.inner {
  width: 100%;
  max-width: calc( 1000px + 3% * 2);
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: auto;
  margin-right: auto;
}

.section {
  padding: 80px 0;
}

@media screen and (max-width: 991px) {
  .section {
    padding: 40px 0;
  }
}

.section.is--start {
  padding-top: 0;
}

.section.is--end {
  padding-bottom: 0;
}

.c-block {
  display: inline-block;
}

.c-trim {
  /*-画像を内包する親要素-*/
  width: 100%;
  position: relative;
  overflow: hidden;
}

.c-trim:after {
  /*-c-trim の疑似要素に指定-*/
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 75%;
  /*-画像の縦横比を指定 初期値は75%（LDサイズ）-*/
}

.c-trim .c-trim__inner {
  /*-c-trim の直下にあるdivまたはaタグに指定-*/
  position: absolute !important;
  width: 100%;
  height: 100%;
}

.c-trim .c-trim__inner img {
  /*-トリミングしたい画像や要素に指定*/
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

/* Object
-------------------------------------------------- */
/**
 * Component
 */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _pagination.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Pagination
   ================================================== */
.pagination,
.wp-pagenavi {
  width: 100%;
  margin: 60px 0;
  padding: 0;
  text-align: center;
}

.pagination a,
.pagination > span,
.wp-pagenavi a,
.wp-pagenavi > span {
  position: relative;
  display: inline-block;
  margin: 0.5em 0.8em;
  padding: 0.8em 1.2em;
  text-decoration: none;
  width: auto;
  color: #202625;
  background: #EBEBEB;
}

@media screen and (max-width: 767px) {
  .pagination a,
  .pagination > span,
  .wp-pagenavi a,
  .wp-pagenavi > span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.pagination a:hover,
.wp-pagenavi a:hover {
  opacity: 0.5;
}

.pagination .current,
.wp-pagenavi .current {
  color: #fff;
  background: #F5F5F5;
}

.post-content .pagination,
.post-content .wp-pagenavi {
  margin: 0;
}

/**
 * Utility
 */
/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _c-flexexbox.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
  flexbox Style
================================================== */
.c-flex {
  width: 100%;
  display: -webkit-c-flexex;
  display: c-flexex;
}

.c-flex.c-wrap--wrap {
  c-flexex-wrap: wrap;
}

.c-flex.c-wrap--nowrap {
  c-flexex-wrap: nowrap;
}

.c-flex.c-justify--between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.c-flex.c-justify--start {
  -webkit-justify-content: c-flexex-start;
  justify-content: c-flexex-start;
}

.c-flex.c-justify--center {
  -webkit-justify-content: center;
  justify-content: center;
}

.c-flex.c-justify--end {
  -webkit-justify-content: c-flexex-end;
  justify-content: c-flexex-end;
}

.c-flex.c-align--start {
  align-items: c-flexex-start;
}

.c-flex.c-align--center {
  align-items: center;
}

.c-flex.c-align--end {
  align-items: c-flexex-end;
}

.c-flex.c-direction--row {
  c-flexex-direction: row;
}

.c-flex.c-direction--row-r {
  c-flexex-direction: row-reverse;
}

.c-flex.c-direction--col {
  c-flexex-direction: column;
}

.c-flex.c-direction--col-r {
  c-flexex-direction: column-reverse;
}

.c-flex .c-basis--auto {
  c-flexex-basis: auto;
}

.c-flex .c-self--left {
  margin-left: 0;
  margin-right: auto;
}

.c-flex .c-self--right {
  margin-right: 0;
  margin-left: auto;
}

@media screen and (max-width: 1199px) {
  .c-flex-min--xlg {
    display: block !important;
  }
}

@media screen and (min-width: 1200px) {
  .c-flex-max--xlg {
    display: block !important;
  }
}

@media screen and (max-width: 1079px) {
  .c-flex-min--lg {
    display: block !important;
  }
}

@media screen and (min-width: 1080px) {
  .c-flex-max--lg {
    display: block !important;
  }
}

@media screen and (max-width: 991px) {
  .c-flex-min--md {
    display: block !important;
  }
}

@media screen and (min-width: 992px) {
  .c-flex-max--md {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .c-flex-min--sm {
    display: block !important;
  }
}

@media screen and (min-width: 768px) {
  .c-flex-max--sm {
    display: block !important;
  }
}

@media screen and (max-width: 479px) {
  .c-flex-min--xs {
    display: block !important;
  }
}

@media screen and (min-width: 480px) {
  .c-flex-max--xs {
    display: block !important;
  }
}

/* ======================================================

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

-> scss -> module -> _display.scss

- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

====================================================== */
/* ==================================================
   Display Style
================================================== */
@media screen and (max-width: 1079px) {
  .c-min-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1080px) {
  .c-max-lg {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .c-min-md {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .c-max-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .c-min-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .c-max-sm {
    display: none !important;
  }
}

@media screen and (max-width: 479px) {
  .c-min-xs {
    display: none !important;
  }
}

@media screen and (min-width: 480px) {
  .c-max-xs {
    display: none !important;
  }
}

.c-block {
  display: inline-block;
}

/* ==================================================
   Component
================================================== */
.p-display-switching {
  text-align: right;
  margin-bottom: 15px;
}

.p-display-switching dl {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.p-display-switching dl dt {
  display: inline-block;
  margin-right: 10px;
}

.p-display-switching dl dd {
  display: inline-block;
}

.p-display-switching dl dd:nth-child(n+2) {
  margin-right: 10px;
}

/* ==================================================
   Search
================================================== */
.p-search {
  padding-bottom: 60px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.p-search-container {
  padding: 0 20px 20px !important;
  border-radius: 0 0 4px 4px;
  background-color: #004643;
  border-top: none;
}

@media screen and (max-width: 767px) {
  .p-search-container {
    padding: 1em !important;
  }
}

.p-search-container > div {
  background-color: #fff;
  padding: 1.2em 1em 1em;
}

.p-search-lead {
  padding: 60px 0;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .p-search-lead {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 2em 0;
  }
}

.p-search-lead a {
  display: inline-block;
  text-decoration: underline;
  margin: 0 0.2em;
}

.p-search-title {
  position: relative;
  top: 4px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: #004643;
  padding: 0.8em 20px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .p-search-title {
    padding: 0.8em;
  }
}

.p-search-title img {
  margin-right: 1em;
  margin-left: -1em;
}

.p-search-title:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%) rotate(180deg);
  background-image: url(../img/post/icon_angle.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 12px;
  height: 6px;
}

.p-search-title.js-active {
  border-radius: 4px 4px 0 0;
}

.p-search-title.js-active:after {
  transform: translateY(-50%) rotate(0);
}

.p-search-none {
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 600;
  color: #777;
}

@media screen and (max-width: 767px) {
  .p-search-none {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-search .searchandfilter ul {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.p-search .searchandfilter input {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
}

.p-search .searchandfilter .sf-field-search {
  flex: 1 1 calc( 100% - 44px);
  height: 44px;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .p-search .searchandfilter .sf-field-search {
    flex-basis: calc( 100% - 40px);
    height: 40px;
  }
}

.p-search .searchandfilter .sf-field-search label {
  display: block;
  width: 100%;
}

.p-search .searchandfilter .sf-field-search label input {
  display: block;
  width: 100%;
  border: 1px solid #004643;
  border-radius: 0;
  height: 44px;
  line-height: 44px;
  padding: 0.5em;
}

@media screen and (max-width: 767px) {
  .p-search .searchandfilter .sf-field-search label input {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-search .searchandfilter h4 {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-radius: 5px;
  padding: 0.3em 0.5em;
  background-color: #DCEFEA;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .p-search .searchandfilter h4 {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-search .searchandfilter h4:before {
  width: 1.6em;
  height: 1.6em;
  margin-right: 1.6em;
}

.p-search .searchandfilter [class*="sf-field-taxonomy"] {
  flex: 0 0 100%;
}

.p-search .searchandfilter [class*="sf-field-taxonomy"] ul {
  padding: 0.5em 0 0;
  line-height: 1.6;
}

.p-search .searchandfilter [class*="sf-field-taxonomy"] ul li {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: auto;
  padding-right: 10px;
}

@media screen and (max-width: 767px) {
  .p-search .searchandfilter [class*="sf-field-taxonomy"] ul li {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.p-search .searchandfilter [class*="sf-field-taxonomy"] ul li input {
  position: relative;
  top: -0.1em;
  font-size: 1em;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  margin: 0;
}

.p-search .searchandfilter .sf-field-search + .sf-field-submit {
  width: 44px;
  height: 44px;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .p-search .searchandfilter .sf-field-search + .sf-field-submit {
    right: 0;
    width: 40px;
    height: 40px;
  }
}

.p-search .searchandfilter .sf-field-search + .sf-field-submit input {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #004643;
  background-image: url(../img/post/icon_search.svg);
  background-repeat: no-repeat;
  background-position: left 40% top 40%;
  background-size: auto 60%;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.p-search .searchandfilter [class*="sf-field-taxonomy"] + .sf-field-submit {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 222px;
  border: none;
  position: relative;
  text-align: center;
  background-color: #F9BC60;
  font-size: 15px;
  font-size: 1.5rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  max-height: 40px;
  margin: 40px auto 0;
  padding: 0;
  /*-
      &:after{
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 3.5px 0 3.5px 6px;
        border-color: transparent transparent transparent #C48424;
      }
      -*/
}

@media screen and (max-width: 479px) {
  .p-search .searchandfilter [class*="sf-field-taxonomy"] + .sf-field-submit {
    margin-top: 1em;
    max-width: 100%;
  }
}

.p-search .searchandfilter [class*="sf-field-taxonomy"] + .sf-field-submit input {
  background-color: transparent;
  border: none;
  font-weight: 600;
  display: block;
  width: 100%;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.p-search.is--government {
  position: relative;
  /*-
    .sf-field-search{
      position: absolute;
      top: 0;
      width: calc( 100% - 44px );
      max-width: calc( 800px - 44px );
      left: calc( 50% - 400px );

      @include max-width(lg){
        left: 0;
        width: calc( 100% - 44px );
        max-width: calc( 100% - 44px );
      }
      @include max-width(sm){
        left: 0;
        width: calc( 100% - 40px );
        max-width: calc( 100% - 40px );
      }
    }
    .sf-field-search + .sf-field-submit{
      position: absolute;
      top: 0;
      right: calc( 50% - 400px + 1px );

      @include max-width(lg){
        right: 0;
      }
    }
    -*/
}

.p-search.is--government .sf-field-taxonomy-subject {
  padding-top: 15px;
}

.p-search.is--government .sf-field-taxonomy-subject h4:before {
  content: url(../img/post/icon_man.svg);
}

.p-search.is--government .sf-field-taxonomy-details h4:before {
  content: url(../img/post/icon_sheet.svg);
}

.p-search.is--supporters .searchandfilter ul {
  padding: 0;
}

.p-search.is--supporters .searchandfilter .sf-field-taxonomy-industry {
  padding: 0;
}

.p-search.is--backnumber {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* ==================================================
   Remodal
================================================== */
.remodal {
  background-color: transparent !important;
  max-width: 1000px !important;
  padding: 0 !important;
}

.p-modal {
  text-align: left;
  border-radius: 5px;
  padding: 30px 60px;
  border: 1px solid #D2D2D2;
  box-shadow: 2px 3px 0 #D2D2D2;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-modal {
    padding: 2em 1em;
  }
}

.p-modal__top {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 25px;
}

@media screen and (max-width: 767px) {
  .p-modal__top {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

.p-modal__top .c-media {
  width: 330px;
  margin-right: 30px;
  flex: 0 1 40%;
}

@media screen and (max-width: 767px) {
  .p-modal__top .c-media {
    width: 100%;
    flex: 0 0 100%;
    margin-right: 0;
  }
}

.p-modal__top .c-media img {
  object-fit: contain;
}

.p-modal__top .c-text {
  flex: 0 1 60%;
  padding-top: 0.5em;
}

@media screen and (max-width: 767px) {
  .p-modal__top .c-text {
    width: 100%;
    flex: 0 0 100%;
    padding-top: 1em;
  }
}

.p-modal__top .c-text .c-title {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .p-modal__contents dt, .p-modal__contents dd {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-modal__contents dt {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-radius: 5px;
  padding: 0.3em 0.5em;
  background-color: #DCEFEA;
  font-weight: 600;
}

.p-modal__contents dt img {
  width: 1.6em;
  margin-right: 1.6em;
  height: auto;
}

.p-modal__contents.is--row {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
  .p-modal__contents.is--row dt:nth-of-type(n+2), .p-modal__contents.is--row dd:nth-of-type(n+2) {
    margin-top: 0.8em;
  }
}

.p-modal__contents.is--row dt {
  flex: 0 0 8.4em;
  margin-right: 1.2em;
}

.p-modal__contents.is--row dd {
  flex: 1 1 calc( 100% - 9.6em);
  align-self: center;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .p-modal__contents.is--row dd {
    padding: 0.5em 0 1em;
    width: 100%;
    flex: 0 0 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-modal__contents.is--row.is--nowrap {
    flex-wrap: nowrap;
  }
}

.p-modal__contents.is--row.is--nowrap dt, .p-modal__contents.is--row.is--nowrap dd {
  margin-top: 0;
}

.p-modal__contents.is--column dd {
  padding: 1em 2em 2em;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .p-modal__contents.is--column dd {
    padding: 0.5em 0 1em;
  }
}

@media screen and (min-width: 768px) {
  .p-modal__contents + .p-modal__contents {
    margin-top: 1em;
  }
}

.p-modal__table {
  table-layout: fixed;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-modal__table tbody,
  .p-modal__table tr,
  .p-modal__table th,
  .p-modal__table td {
    display: block;
  }
}

.p-modal__table th, .p-modal__table td {
  padding: 1em;
  line-height: 1.6;
  border-bottom: 1px solid #89BAAC;
  font-size: 14px;
  font-size: 1.4rem;
  vertical-align: middle;
}

.p-modal__table th {
  background-color: #ABD1C6;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .p-modal__table th {
    width: 160px;
  }
}

@media screen and (max-width: 767px) {
  .p-modal__table th {
    width: 100%;
    padding: 0.3em 1em;
  }
}

.p-modal__table td {
  background-color: #F9FFFD;
}

@media screen and (max-width: 767px) {
  .p-modal__table td {
    padding: 0.8em 1em 1.5em;
  }
}

.p-modal__table td a {
  text-decoration: underline;
  color: #004F80;
}

.p-modal .c-btn {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 222px;
  border: none;
  position: relative;
  padding: 0.8em;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  font-size: 15px;
  font-size: 1.5rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  max-height: 40px;
}

@media screen and (max-width: 767px) {
  .p-modal .c-btn {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.p-modal .c-btn:after {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3.5px 0 3.5px 6px;
}

.p-modal .c-btn.is--hp {
  background-color: #F9BC60;
  margin-right: 1.6em;
}

@media screen and (max-width: 767px) {
  .p-modal .c-btn.is--hp {
    margin-right: 2%;
  }
}

.p-modal .c-btn.is--hp:after {
  border-color: transparent transparent transparent #C48424;
}

.p-modal .c-btn.is--close, .p-modal .c-btn.is--more {
  background-color: #ABD1C6;
}

.p-modal .c-btn.is--close:after, .p-modal .c-btn.is--more:after {
  border-color: transparent transparent transparent #89BAAC;
}

.p-modal__btn {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}

.p-modal__btn .c-btn {
  flex: 0 1 calc( 50% - 0.8em);
}

@media screen and (max-width: 767px) {
  .p-modal__btn .c-btn {
    flex: 1 1 49%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-modal.is--events {
    padding: 1em;
  }
}

.p-modal.is--advisors .c-media {
  flex: 0 0 220px;
}

.p-modal.is--advisors .c-media:after {
  padding-bottom: 75%;
}

.p-modal.is--advisors .c-text {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.p-modal.is--advisors .c-text .p-advisors__en {
  margin-bottom: 0.5em;
}

.p-modal.is--advisors .c-text .c-title {
  font-size: 22px;
  font-size: 2.2rem;
}

.p-modal.is--advisors .c-text .p-modal__contents.is--row {
  margin-top: auto;
}

.p-modal.is--advisors .c-text .p-modal__contents.is--row dt {
  flex: 0 0 12em;
}

.p-modal.is--advisors .c-text .p-modal__contents.is--row dd {
  flex: 1 1 calc( 100% - 13.2em);
}

@media screen and (max-width: 767px) {
  .p-modal.is--supporters {
    padding: 1em;
  }
}

@media screen and (min-width: 768px) {
  .p-modal.is--supporters .c-media {
    flex: 0 0 220px;
  }
}

.p-modal.is--supporters .c-media:after {
  padding-bottom: 75%;
}

.p-modal.is--supporters .c-text .c-title {
  font-size: 17px;
  font-size: 1.7rem;
}

.p-modal.is--supporters .c-text .p-supporters__term li {
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.p-modal.is--supporters .c-text .p-modal__contents.is--row {
  margin-top: auto;
}

.p-modal.is--supporters .c-text .p-modal__contents.is--row dt {
  flex: 0 0 12em;
}

.p-modal.is--supporters .c-text .p-modal__contents.is--row dd {
  flex: 1 1 calc( 100% - 13.2em);
}

.p-modal.is--subsidy .c-text .c-title, .p-modal.is--government .c-text .c-title, .p-modal.is--overseas .c-text .c-title, .p-modal.is--backnumber .c-text .c-title {
  font-size: 20px;
  font-size: 2rem;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .p-modal.is--subsidy .c-text .c-title, .p-modal.is--government .c-text .c-title, .p-modal.is--overseas .c-text .c-title, .p-modal.is--backnumber .c-text .c-title {
    font-size: 16px;
    font-size: 1.6rem;
    padding-bottom: 0.8em;
    border-bottom: 1px solid #d2d2d2;
  }
}

.p-modal.is--subsidy .c-text .c-title .c-btn, .p-modal.is--government .c-text .c-title .c-btn, .p-modal.is--overseas .c-text .c-title .c-btn, .p-modal.is--backnumber .c-text .c-title .c-btn {
  flex: 0 0 222px;
  margin: 0 0 0 20px;
}

.p-modal.is--subsidy .p-modal__contents.is--row dt, .p-modal.is--government .p-modal__contents.is--row dt, .p-modal.is--overseas .p-modal__contents.is--row dt, .p-modal.is--backnumber .p-modal__contents.is--row dt {
  flex: 0 0 10em;
}

.p-modal.is--subsidy .p-modal__contents.is--row dd, .p-modal.is--government .p-modal__contents.is--row dd, .p-modal.is--overseas .p-modal__contents.is--row dd, .p-modal.is--backnumber .p-modal__contents.is--row dd {
  width: 100%;
  flex: 1 1 calc( 100% - 11.2em);
}

@media screen and (max-width: 767px) {
  .p-modal.is--subsidy .p-modal__contents.is--row dd, .p-modal.is--government .p-modal__contents.is--row dd, .p-modal.is--overseas .p-modal__contents.is--row dd, .p-modal.is--backnumber .p-modal__contents.is--row dd {
    flex-basis: 100%;
  }
}

.p-modal.is--subsidy .p-modal__btn, .p-modal.is--government .p-modal__btn, .p-modal.is--overseas .p-modal__btn, .p-modal.is--backnumber .p-modal__btn {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .p-modal.is--subsidy .p-modal__btn, .p-modal.is--government .p-modal__btn, .p-modal.is--overseas .p-modal__btn, .p-modal.is--backnumber .p-modal__btn {
    margin-top: 1em;
  }
}

[data-remodal-target="modal-email"],
[data-remodal-target="modal-registration"] {
  cursor: pointer;
}

.p-email {
  padding: 40px 120px;
}

@media screen and (max-width: 991px) {
  .p-email {
    padding: 40px 5%;
  }
}

.p-email .maintitle {
  font-weight: 400;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1.11111;
  font-weight: 700;
  color: #000000;
  white-space: pre-wrap;
  margin: 0 0 1em;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium","ヒラギノ角ゴ Pro W3","ヒラギノ角ゴ", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  padding-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px dashed #ED9771;
}

@media screen and (max-width: 767px) {
  .p-email .maintitle {
    font-size: 20px;
    font-size: 2rem;
  }
}

.p-email .maintitle:before {
  content: none;
}

.p-email .maintitle:after {
  top: 0;
  width: 2em;
  height: 2em;
}

.p-email-list__item {
  list-style-type: disc;
  list-style-position: inside;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-email-list__item {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-email-list__item:nth-of-type(n+2) {
  margin-top: 1.5em;
}

.p-email-note {
  font-size: 15px;
  font-size: 1.5rem;
  margin-top: 2em;
}

@media screen and (max-width: 767px) {
  .p-email-note {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.p-email-template {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

.p-email-template__item {
  border: 1px solid #89BAAC;
  flex: 0 0 calc( 50% - 10px);
}

@media screen and (max-width: 767px) {
  .p-email-template__item {
    flex: 0 0 100%;
  }
  .p-email-template__item:nth-child(n+2) {
    margin-top: 20px;
  }
}

.p-email-template__item h3 {
  background-color: #89BAAC;
  color: #fff;
  font-weight: 600;
  padding: 0.5em 1em;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .p-email-template__item h3 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.p-email-template__item dl {
  padding: 2em;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-email-template__item dl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-email-template__item dl dt {
  margin-bottom: 1.5em;
}

.p-email-template__item dl dd ul li:nth-of-type(n+2) {
  margin-top: 0.5em;
}

.p-email-template__item dl dd ul li.has--indent {
  padding-left: 1em;
}

.p-email-template__item > ul {
  padding: 2em 2em 2em 3em;
  font-size: 16px;
  font-size: 1.6rem;
  text-indent: -1em;
}

@media screen and (max-width: 767px) {
  .p-email-template__item > ul {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-email-template__item > ul li {
  list-style-position: inside;
  list-style-type: disc;
}

.p-email-template__item > ul li:nth-of-type(n+2) {
  margin-top: 1em;
}

.p-email-template__item.is--registration, .p-email-template__item.is--en {
  flex: 0 0 100%;
}

.p-email-template__item.is--consultation {
  flex: 0 0 100%;
  margin-top: 20px;
}

.p-email-template__item.is--consultation h3 {
  background-color: #ED9771;
  border-color: #ED9771;
  background-image: url(../img/service/constraction_request_contents_decoration.svg);
  background-repeat: no-repeat;
  background-position: left 1em center;
  background-size: auto 1em;
  padding-left: 3em;
}

.p-email .p-modal__btn {
  padding-top: 40px;
}

/* ==================================================
   Events
================================================== */
.p-events-list__item .c-media {
  margin-bottom: 0.5em;
}

.p-events-list__item .c-media img {
  object-fit: contain;
}

.p-events-list__item__top {
  padding: 1em;
}

.p-events-list.is--tile {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.p-events-list.is--tile .p-events-list__item {
  background-color: #fff;
  box-shadow: 2px 3px 0 #D2D2D2;
  border: 1px solid #D2D2D2;
  width: 25%;
  flex: 0 1 calc( 25% - 15px);
  cursor: pointer;
  border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .p-events-list.is--tile .p-events-list__item {
    width: 100%;
    flex: 0 1 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-events-list.is--tile .p-events-list__item:not(:nth-child(4n)) {
    margin-right: 20px;
  }
  .p-events-list.is--tile .p-events-list__item:nth-child(n+5) {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  .p-events-list.is--tile .p-events-list__item:nth-child(n+2) {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-events-list.is--tile .p-events-list__item__top {
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .p-events-list.is--tile .p-events-list__item .c-media {
    flex: 0 0 120px;
  }
}

.p-events-list.is--tile .p-events-list__item a {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .p-events-list.is--tile .p-events-list__item .c-text {
    flex: 1 1 calc( 100% - 120px);
    padding-left: 1em;
  }
}

.p-events-list.is--tile .p-events-list__item .c-text .c-title {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}

.p-events-list.is--list .p-events-list__item:nth-of-type(n+2) {
  margin-top: 35px;
}

.p-events__schedule {
  margin-top: 1.5em;
  font-size: 12px;
  font-size: 1.2rem;
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.p-events__schedule dt:nth-of-type(n+2), .p-events__schedule dd:nth-of-type(n+2) {
  margin-top: 0.5em;
}

.p-events__schedule dt {
  flex: 0 0 3em;
}

.p-events__schedule dd {
  flex: 1 1 calc( 100% - 3em);
}

.p-events__more {
  position: relative;
  width: 100%;
  padding: 0.8em;
  border-top: 1px dashed #89BAAC;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .p-events__more {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-events__more:after {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3.5px 0 3.5px 6px;
  border-color: transparent transparent transparent #000000;
}

/* ==================================================
   Advisors
================================================== */
.p-advisors-list {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.p-advisors-list__item {
  background-color: #fff;
  box-shadow: 2px 3px 0 #D2D2D2;
  width: 33.3333%;
  flex: 0 1 calc( 33.3333% - 40px / 3);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #D2D2D2;
}

@media screen and (max-width: 767px) {
  .p-advisors-list__item {
    width: 100%;
    flex: 0 0 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-advisors-list__item:not(:nth-child(3n)) {
    margin-right: 20px;
  }
  .p-advisors-list__item:nth-child(n+4) {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-advisors-list__item:nth-child(n+2) {
    margin-top: 20px;
  }
}

.p-advisors-list__item .c-media {
  margin-bottom: 0.5em;
}

.p-advisors-list__item .c-media:after {
  padding-bottom: 73.10%;
}

.p-advisors-list__item .c-media img {
  object-fit: contain;
}

.p-advisors-list__item .c-text .c-title {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .p-advisors-list__item .c-text .c-title {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.p-advisors-list__item__top {
  padding: 1em;
}

.p-advisors__attribute {
  display: block;
  min-width: 118px;
  border-radius: 5px;
  padding: 0.5em;
  margin-bottom: 0.5em;
  background-color: #DCEFEA;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.3;
}

.p-advisors__position {
  margin-top: 1em;
}

.p-advisors__position li {
  list-style-type: disc;
  list-style-position: inside;
}

.p-advisors__position li:nth-of-type(n+2) {
  margin-top: 0.5em;
}

@media screen and (max-width: 767px) {
  .p-advisors__position li {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-advisors__position + .p-modal__contents {
  margin-top: 1em !important;
}

.p-advisors__more {
  position: relative;
  padding: 0.8em;
  border-top: 1px dashed #89BAAC;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .p-advisors__more {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-advisors__more:after {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3.5px 0 3.5px 6px;
  border-color: transparent transparent transparent #000000;
}

/* ==================================================
   Supporters
================================================== */
.p-supporters .inner {
  width: 100%;
  max-width: calc( 1592px + 3% * 2);
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: auto;
  margin-right: auto;
}

.p-supporters .js-toggle-contents {
  display: none;
  padding-top: 35px;
}

.p-supporters-list__item .c-media {
  margin-bottom: 0.8em;
}

.p-supporters-list__item .c-media img {
  object-fit: contain;
}

.p-supporters-list__item__top {
  padding: 1em;
}

@media screen and (max-width: 767px) {
  .p-supporters-list__item__top {
    padding: 0.6em;
  }
}

.p-supporters-list.is--tile {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.p-supporters-list.is--tile .p-supporters-list__item {
  background-color: #fff;
  box-shadow: 2px 3px 0 #D2D2D2;
  width: 14.2857%;
  flex: 0 1 calc( 14.2857% - 18px);
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #D2D2D2;
}

@media only screen and (min-width: 1279px) and (max-width: 768px) {
  .p-supporters-list.is--tile .p-supporters-list__item {
    width: 25%;
    flex: 0 1 calc( 25% - 15px);
  }
}

@media screen and (max-width: 767px) {
  .p-supporters-list.is--tile .p-supporters-list__item {
    width: 50%;
    flex: 0 1 calc( 50% - 10px);
  }
}

@media screen and (min-width: 1080px) {
  .p-supporters-list.is--tile .p-supporters-list__item:not(:nth-child(7n)) {
    margin-right: 20px;
  }
  .p-supporters-list.is--tile .p-supporters-list__item:nth-child(n+8) {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1279px) and (max-width: 768px) {
  .p-supporters-list.is--tile .p-supporters-list__item:not(:nth-child(4n)) {
    margin-right: 20px;
  }
  .p-supporters-list.is--tile .p-supporters-list__item:nth-child(n+5) {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-supporters-list.is--tile .p-supporters-list__item:not(:nth-child(2n)) {
    margin-right: 10px;
  }
  .p-supporters-list.is--tile .p-supporters-list__item:nth-child(n+3) {
    margin-top: 20px;
  }
}

.p-supporters-list.is--tile .p-supporters-list__item .c-text .c-title {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
  word-break: break-all;
}

.p-supporters-list.is--tile .p-supporters-list__item .c-text .p-supporters__term li {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  display: inline-block;
  padding: 0.5em 1em;
  background-color: #fff;
  font-size: 11px;
  font-size: 1.1rem;
  background-color: #F6CB89;
  text-align: center;
  font-weight: 600;
  line-height: 1.3;
}

@media screen and (max-width: 767px) {
  .p-supporters-list.is--tile .p-supporters-list__item .c-text .p-supporters__term li {
    font-size: 10px;
    font-size: 1rem;
  }
}

.p-supporters-list.is--list {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.p-supporters-list.is--list .p-supporters-list__item:nth-of-type(n+2) {
  margin-top: 35px;
}

/* ==================================================
   Subsidy
================================================== */
.p-subsidy-list__item:nth-of-type(n+2) {
  margin-top: 35px;
}

/* ==================================================
   Government
================================================== */
.p-government .js-toggle-contents {
  display: none;
  padding-top: 35px;
}

.p-government-nav {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
}

.p-government-nav .c-btn {
  flex: 0 0 calc( 50% - 10px);
  line-height: 50px;
  height: 50px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  border-radius: 4px;
  font-weight: 600;
}

.p-government-nav .c-btn.is--country {
  background-color: #EE8354;
  border-bottom-color: #9F3031;
}

.p-government-nav .c-btn.is--municipality {
  background-color: #89BAAC;
  border-bottom-color: #004643;
}

.p-government-list__item .p-modal__contents.is--row {
  align-items: center;
}

@media screen and (min-width: 768px) {
  .p-government-list__item .organization,
  .p-government-list__item .country {
    max-width: 4em;
  }
}

.p-government-list__item:nth-of-type(n+2) {
  margin-top: 35px;
}

.p-government__term li {
  display: inline-block;
  margin-right: 0.5em;
}

/* ==================================================
   Overseas
================================================== */
.p-overseas-imagemap {
  margin: 0 auto;
}

.p-overseas-list__item:nth-of-type(n+2) {
  margin-top: 35px;
}

.p-overseas__term {
  margin-bottom: 1em;
}

.p-overseas__term li {
  display: inline-block;
  margin-right: 0.5em;
  width: 118px;
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-overseas__term li {
    width: auto;
    min-width: 80px;
    font-size: 12px;
    font-size: 1.2rem;
    border-radius: 2px;
  }
}

.p-overseas__term li.asia, .p-overseas__term li.south-america {
  background-color: #70BCC3;
}

.p-overseas__term li.africa {
  background-color: #F0B04F;
}

.p-overseas__term li.oceania {
  background-color: #B2823B;
}

.p-overseas__term li.europa {
  background-color: #3D85CB;
}

.p-overseas__term li.north-america {
  background-color: #E84F4F;
}

/* ==================================================
   Backnumber
================================================== */
.p-backnumber-list__item .p-modal__contents.is--row {
  align-items: center;
}

.p-backnumber-list__item .p-modal__contents.is--row dt img {
  margin-right: 0.8em;
}

.p-backnumber-list__item .p-modal__contents.is--row dt.company {
  flex: 0 0 10em;
}

.p-backnumber-list__item .p-modal__contents.is--row dd {
  flex: 1 1 calc( 100% - 11.2em);
}

.p-backnumber-list__item .p-modal__contents.is--row dd.company {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.p-backnumber-list__item .p-modal__contents.is--row dd.company .c-btn {
  margin-right: 0;
  margin-left: 1em;
}

@media screen and (min-width: 768px) {
  .p-backnumber-list__item .organization,
  .p-backnumber-list__item .country {
    max-width: 6em;
  }
}

.p-backnumber-list__item:nth-of-type(n+2) {
  margin-top: 35px;
}

.p-backnumber__term li {
  display: inline-block;
  margin-right: 0.5em;
}

/* ==================================================
   Custom
================================================== */
#footer > .contents > .columnbox > .detailbox .navitem > .item {
  text-transform: capitalize;
}

.top > .support_information_event-wrap > .eventSlider .eventcard a {
  height: 100%;
}

.top > .support_information_event-wrap > .eventSlider .eventcard a > .read {
  margin-top: auto;
}

/* プライバシーポリシー */
.privacy > .privacy-wrap > .contents > .privacybox > .detail p:nth-child(n+2) {
  margin-top: 1em;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail h3 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ul {
  margin-top: 0.5em;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ul li {
  list-style-position: inside;
  margin-bottom: 0 !important;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ol.list1 {
  counter-reset: list1 0;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ol.list1 > li {
  padding-left: 3em;
  position: relative;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ol.list1 > li:before {
  position: absolute;
  left: 0;
  top: 0;
  counter-increment: list1 1;
  content: "（" counter(list1) "）";
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ol.list2 {
  counter-reset: list2 0;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ol.list2 > li {
  padding-left: 3em;
  position: relative;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail ol.list2 > li:before {
  position: absolute;
  left: 0;
  top: 0;
  counter-increment: list2 1;
  content: "（" counter(list2, upper-alpha) "）";
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail .gabox {
  margin-top: 1em;
  padding: 2em;
  background-color: #EBF2F0;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail .gabox p, .privacy > .privacy-wrap > .contents > .privacybox > .detail .gabox ul {
  font-size: 0.9em;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail .gabox h5 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.privacy > .privacy-wrap > .contents > .privacybox > .detail .gabox ul li {
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .fixedcontents {
    display: none !important;
  }
}

#header > .headerbottom > .logobox {
  min-width: 120px;
}

@media screen and (min-width: 1281px) {
  #header > .headerbottom {
    padding-right: 200px;
  }
}

#header > .headerbottom > .navigationwrap {
  margin-right: 0;
}

#header > .headerbottom > .navigationwrap > .contact {
  right: 0;
}

#header > .headerbottom > .navigationwrap > .navigationitem > .item {
  flex-basis: auto;
}

#header > .headerbottom > .navigationwrap > .navigationitem > .item > a {
  font-size: 1.4rem;
  padding: 0 1.5em;
  white-space: nowrap;
  margin: 0 !important;
}

#header > .headerbottom > .navigationwrap > .navigationitem > .item > a:after {
  right: 0;
  width: 0.5em;
  height: 0.5em;
}

@media screen and (max-width: 1280px) and (min-width: 1025px) {
  #header > .headerbottom {
    padding-right: 90px;
  }
  #header > .headerbottom > .navigationwrap > .navigationitem > .item > a {
    font-size: 1.3rem;
  }
}
